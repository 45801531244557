import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/index.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/animations/shift-away.css'
import { ReviewsService } from '@core/application/reviews.service'
import { NagivationEntry, navigation, router } from './router'
import { HttpClient } from '@infrastructure/datasource/http-client'
import { ProjectsRepository } from '@infrastructure/datasource/projects.repository'
import { ProjectsService } from '@core/application/projects.service'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { UsersService } from '@core/application/users.service'
import { UsersRepository } from '@infrastructure/datasource/users.repository'
import useAuth from './composables/use-auth'
import * as Sentry from '@sentry/vue'
import { Api } from '@infrastructure/datasource/review-manager-api'
import { useSettings } from './composables/use-settings'
import { CslStylesService } from '@core/application/csl-styles.service'
import { CslStyleApi } from '@infrastructure/csl-styles.api'
import useCslStyles from './composables/use-cslStyles'
import tippy, { Instance } from 'tippy.js'
import {
  AuthKey,
  DevToolboxKey,
  ProjectsServiceKey,
  ReviewsServiceKey,
  SettingsServiceKey,
  UsersServiceKey,
} from './injectionKeys'
import {
  EventHandler,
  EventHandlerKey,
} from '@infrastructure/eventHandler/eventHandler'
import { SettingsService } from '@core/application/settings.service'
import useDevToolbox from './composables/use-dev-toolbox'
import { BuiltInImportSourcesService } from '@core/application/built-in-import-sources.service'
import { BuiltInImportSourcesRepository } from '@infrastructure/datasource/built-in-import-sources.repository'
import useBuiltInImportSources from './composables/use-built-in-import-sources'
import { useSessionStorage } from '@vueuse/core'
import { User } from '@core/domain/models/user.model'
import { Role } from '@core/domain/types/role.type'
import tos from './tos'
import { isAxiosError } from 'axios'

async function bootstrap() {
  const app = createApp(App)
  const response = await fetch('/config.json')
  const config = await response.json()

  Sentry.init({
    app,
    trackComponents: true,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: config.sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.feedbackIntegration({
        colorScheme: 'light',
        triggerLabel: 'Feedback',
        formTitle: 'Feedback and bug report',
        showBranding: false,
        messagePlaceholder: 'Please provide your feedback',
        submitButtonLabel: 'Submit',
        successMessageText: 'Thank you for your feedback!',
      }),
    ],
    profilesSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/evidence\.systems\/*/],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint.originalException

      if (isAxiosError(error)) {
        const method = (error.config?.method ?? 'unknown').toUpperCase()
        const url = error.config?.url
          ? new URL(error.config.url, window.location.origin)
          : new URL('unknown', window.location.origin)
        const status = error.response?.status ?? 'Network Error'

        if (event.exception?.values?.[0]) {
          event.exception.values[0].type = `${method} ${url.pathname} failed: ${status}`
          event.exception.values[0].value = `Request failed with status ${status}`
        }

        event.extra = {
          ...event.extra,
          request: {
            url: url.toString(),
            method: method,
            headers: error.config?.headers ?? {},
            data: error.config?.data ?? null,
          },
          response: error.response
            ? {
                status: error.response.status,
                statusText: error.response.statusText,
                headers: error.response.headers ?? {},
                data: error.response.data ?? null,
              }
            : 'No response (possible network error)',
        }

        event.fingerprint = [
          '{{ default }}',
          method,
          url.pathname,
          String(status),
        ]

        event.tags = {
          ...event.tags,
          method: method,
          endpoint: url.pathname,
          status: String(status),
        }
      }
      return event
    },
  })

  const httpClient = new HttpClient()
  const reviewManagerService = new Api()
  const usersRepository = new UsersRepository(httpClient, reviewManagerService)
  const usersService = new UsersService(usersRepository, reviewManagerService)

  app.directive('tooltip', {
    created: (el, { value, modifiers }) => {
      let placement: 'right' | 'left' | 'top' | 'bottom' | undefined
      if (modifiers.right) {
        placement = 'right'
      }
      if (modifiers.left) {
        placement = 'left'
      }
      if (modifiers.top) {
        placement = 'top'
      }
      if (modifiers.bottom) {
        placement = 'bottom'
      }

      let theme = ''
      if (modifiers.light) {
        theme = 'light'
      }
      if (modifiers.error) {
        theme = 'error'
      }
      el.$tippy = tippy(el, {
        content: value,
        maxWidth: 500,
        placement,
        interactive: modifiers.interactive ?? false,
        theme,
        zIndex: 99999999999999,
        allowHTML: true,
      })
      if (!value) {
        ;(el.$tippy as Instance).disable()
      }
    },
    updated: (el, { value }) => {
      ;(el.$tippy as Instance).setContent(value)
      if (!value) {
        ;(el.$tippy as Instance).disable()
      } else {
        ;(el.$tippy as Instance).enable()
      }
    },
    beforeUnmount(el) {
      ;(el.$tippy as Instance).destroy()
    },
  })

  const projectsRepository = new ProjectsRepository(
    httpClient,
    reviewManagerService,
  )

  const reviewsRepository = new ReviewsRepository(reviewManagerService)
  const settingsService = new SettingsService(reviewManagerService)

  const builtInImportSourcesRepository = new BuiltInImportSourcesRepository(
    reviewManagerService,
  )
  const projectsService = new ProjectsService(projectsRepository)
  const cslStyleApi = new CslStyleApi(reviewManagerService)
  const cslStylesService = new CslStylesService(cslStyleApi)
  const reviewsService = new ReviewsService(reviewsRepository)

  const builtInImportSourcesService = new BuiltInImportSourcesService(
    builtInImportSourcesRepository,
  )
  const eventHandler = new EventHandler()
  const auth = useAuth(usersService)
  const devToolbox = useDevToolbox(config.isCuttingEdge)

  app.provide('billingPortalUrl', config.billingPortalUrl)
  app.provide('isBillingDisabled', config.isBillingDisabled)
  app.provide(ReviewsServiceKey, reviewsService)
  app.provide(ProjectsServiceKey, projectsService)
  app.provide(UsersServiceKey, usersService)
  app.provide('cslStylesService', cslStylesService)
  app.provide('builtInImportSourcesService', builtInImportSourcesService)
  app.provide(EventHandlerKey, eventHandler)
  app.provide(SettingsServiceKey, settingsService)
  app.provide(AuthKey, auth)
  app.provide(DevToolboxKey, devToolbox)

  const cslStyles = useCslStyles()
  const builtInImportSources = useBuiltInImportSources()

  const settings = useSettings()
  await settings.init()
  const user = await usersService.findCurrentUser().catch(() => null)
  app.use(router)
  if (user) {
    auth.setUser(user)
    Sentry.setUser({
      email: user.email,
    })
  }
  const isLoginRoute = (path: string) => path === '/login'
  const isForgotPasswordRoute = (path: string) => path === '/forgot-password'
  const isTermsOfServiceRoute = (path: string) => path === '/terms-of-service'
  const isPublicRoute = (path: string) =>
    isLoginRoute(path) ||
    isForgotPasswordRoute(path) ||
    isTermsOfServiceRoute(path)
  const isUserAllowed = (user: User | null) => user && !user.isDisabled
  const hasRequiredRole = (route: NagivationEntry, userRole: Role) =>
    !route.roles.length || route.roles.includes(userRole)
  const hasUserAcceptedLatestTerms = (user: User | null) =>
    user?.acceptedTermsVersion === tos.version

  router.beforeEach(async (to, from, next) => {
    const preLoginRoute = useSessionStorage('preLoginRoute', '/')

    // Save the pre-login route
    if (
      isPublicRoute(to.path) &&
      from.fullPath &&
      !isPublicRoute(from.fullPath)
    ) {
      preLoginRoute.value = from.fullPath
    }

    // Redirect logged-in users away from login and forgot-password pages
    if (
      isUserAllowed(user) &&
      isPublicRoute(to.path) &&
      hasUserAcceptedLatestTerms(user)
    ) {
      const path = preLoginRoute.value
      preLoginRoute.value = '/'
      return next(path)
    }

    // Allow access to login and forgot-password pages
    if (isPublicRoute(to.path)) {
      return next()
    }

    // Redirect to login if user is not logged in or disabled
    if (!isUserAllowed(user)) {
      return next('/login')
    }

    // Redirect to TOS page if user has not accepted the latest terms
    if (isUserAllowed(user) && !hasUserAcceptedLatestTerms(user)) {
      return next('/terms-of-service')
    }

    // Check if the route exists in the navigation
    const route = navigation.find((r) => r.name === to.name)
    if (!route) {
      return next()
    }

    // Check if the user has the required role for the route
    if (!user?.role || !hasRequiredRole(route, user.role)) {
      return next('/403')
    }

    // Allow navigation to the requested route

    return next()
  })

  app.mount('#app')
  router.isReady().then(() => {
    const currentRoute = router.currentRoute.value
    if (
      currentRoute.path !== '/login' &&
      currentRoute.path !== '/forgot-password'
    ) {
      cslStylesService
        .find()
        .then((cslStylesList) => cslStyles.set(cslStylesList))
        .catch(() => [])

      builtInImportSourcesService
        .find()
        .then((sources) => builtInImportSources.set(sources))
        .catch(() => [])
    }
  })
}

bootstrap()
