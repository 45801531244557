import { Study } from '@core/domain/models/study.model'
import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { MetaData } from '@core/domain/types/meta-data.type'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ImportSearchJob } from '@core/domain/models/importSearchJob.model'
import { PdfStudyRetrievalStatus } from '@core/domain/types/PdfStudyRetrievalStatus'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'

export class ReviewsService {
  constructor(private _reviewsRepository: ReviewsRepository) {}

  async findById(reviewId: Id) {
    return this._reviewsRepository.findById(reviewId)
  }

  async markStudyAsDuplicate(
    reviewId: Id,
    studyId: Id,
    parentStudyId: Id,
  ): Promise<void> {
    return this._reviewsRepository.markStudyAsDuplicate(
      reviewId,
      studyId,
      parentStudyId,
    )
  }

  async markStudyAsNotDuplicate(reviewId: Id, studyId: Id): Promise<void> {
    return this._reviewsRepository.markStudyAsNotDuplicate(reviewId, studyId)
  }

  async setStudyTitleAndAbstractScreening(
    reviewId: Id,
    studyId: Id,
    titleAndAbstractScreening: string,
  ) {
    return this._reviewsRepository.setStudyTitleAndAbstractScreening(
      reviewId,
      studyId,
      titleAndAbstractScreening,
    )
  }

  async clearStudyTitleAndAbstractScreening(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyTitleAndAbstractScreening(
      reviewId,
      studyId,
    )
  }

  async setStudyFullTextScreening(
    reviewId: Id,
    studyId: Id,
    fullTextScreening: string,
  ) {
    return this._reviewsRepository.setStudyFullTextScreening(
      reviewId,
      studyId,
      fullTextScreening,
    )
  }

  async clearStudyFullTextScreening(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyFullTextScreening(
      reviewId,
      studyId,
    )
  }

  async favoriteStudy(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.favoriteStudy(reviewId, studyId)
  }
  async unfavoriteStudy(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.unfavoriteStudy(reviewId, studyId)
  }

  async removeSearch(reviewId: Id, searchId: number) {
    return this._reviewsRepository.deleteSearchStudies(reviewId, searchId)
  }

  async planScreening(
    reviewId: Id,
    data: {
      titleAndAbstractCriteria: string[]
      fullTextCriteria: string[]
    },
  ) {
    return this._reviewsRepository.planScreening(reviewId, data)
  }

  async addFullTextCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.addFullTextCriterion(reviewId, criterion)
  }

  async deleteFullTextCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.deleteFullTextCriterion(reviewId, criterion)
  }

  async enableTitleAndAbstractScreening(reviewId: Id) {
    return this._reviewsRepository.enableTitleAndAbstractScreening(reviewId)
  }

  async disableTitleAndAbstractScreening(reviewId: Id) {
    return this._reviewsRepository.disableTitleAndAbstractScreening(reviewId)
  }

  async addTitleAndAbstractCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.addTitleAndAbstractCriterion(
      reviewId,
      criterion,
    )
  }

  async deleteTitleAndAbstractCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.deleteTitleAndAbstractCriterion(
      reviewId,
      criterion,
    )
  }

  async addImportSourceToPlan(reviewId: Id, importSourceId: string) {
    return this._reviewsRepository.addImportSourceToPlan(
      reviewId,
      importSourceId,
    )
  }

  async removeImportSourceFromPlan(reviewId: Id, importSourceId: string) {
    return this._reviewsRepository.removeImportSourceFromPlan(
      reviewId,
      importSourceId,
    )
  }

  async delete(selectedReviewsIds: Id[]): Promise<void> {
    return this._reviewsRepository.delete(selectedReviewsIds)
  }

  async findProject(reviewId: Id): Promise<Project> {
    return this._reviewsRepository.findProject(reviewId)
  }

  async updateName(reviewId: Id, name: string): Promise<void> {
    return this._reviewsRepository.updateName(reviewId, name)
  }

  async uploadStudyPdfFile(
    reviewId: Id,
    studyId: Id,
    file: File,
  ): Promise<Study> {
    return this._reviewsRepository.uploadStudyPdfFile(reviewId, studyId, file)
  }

  async getStudyPdfFile(reviewId: Id, studyId: Id): Promise<Blob> {
    return this._reviewsRepository.getStudyPdfFile(reviewId, studyId)
  }

  async downloadSearchCitationFile(reviewId: Id, searchId: Id): Promise<Blob> {
    return this._reviewsRepository.downloadSearchCitationFile(
      reviewId,
      searchId,
    )
  }

  async deleteStudyPdfFile(reviewId: Id, studyId: Id): Promise<void> {
    return this._reviewsRepository.deleteStudyPdfFile(reviewId, studyId)
  }

  async downloadPdfZip(reviewId: Id): Promise<Blob> {
    return this._reviewsRepository.downloadPdfZip(reviewId)
  }

  async editStudyAbstract(reviewId: Id, studyId: Id, abstract: string) {
    return this._reviewsRepository.editStudyAbstract(
      reviewId,
      studyId,
      abstract,
    )
  }

  async enableStudiesAppraisalImdrfMdce2019(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalImdrfMdce2019(reviewId)
  }

  async enableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId,
    )
  }

  async enableStudiesAppraisalPeerReviewStatus(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalPeerReviewStatus(
      reviewId,
    )
  }

  async disableStudiesAppraisal(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisal(reviewId)
  }

  async disableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId,
    )
  }

  async disableStudiesAppraisalPeerReviewStatus(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisalPeerReviewStatus(
      reviewId,
    )
  }

  async submitComplaint(
    reviewId: Id,
    studyId: Id,
    data: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    },
  ) {
    return this._reviewsRepository.submitComplaint(reviewId, studyId, data)
  }

  async appraiseStudy(
    reviewId: Id,
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string },
  ) {
    return this._reviewsRepository.appraiseStudy(reviewId, studyId, appraisal)
  }
  async setOxfordLevelOfEvidenceStudy(
    reviewId: Id,
    studyId: Id,
    evidence: OxfordLevelOfEvidenceType,
  ) {
    return this._reviewsRepository.setOxfordLevelOfEvidenceStudy(
      reviewId,
      studyId,
      evidence,
    )
  }

  async setStudyPeerReviewStatus(
    reviewId: Id,
    studyId: Id,
    peerReviewStatus: PeerReviewStatus,
  ) {
    return this._reviewsRepository.setStudyPeerReviewStatus(
      reviewId,
      studyId,
      peerReviewStatus,
    )
  }

  async clearStudiesAppraisal(reviewId: Id, studyId: Id, criterionId: string) {
    return this._reviewsRepository.clearStudiesAppraisal(
      reviewId,
      studyId,
      criterionId,
    )
  }

  async cleaStudyOxfordLevelOfEvidence(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.cleaStudyOxfordLevelOfEvidence(
      reviewId,
      studyId,
    )
  }

  async clearStudyPeerReviewStatus(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyPeerReviewStatus(reviewId, studyId)
  }

  async updateStudy(reviewId: Id, studyId: Id, metaData: MetaData) {
    return this._reviewsRepository.updateStudy(reviewId, studyId, metaData)
  }

  async isCitationFileAlreadyImported(
    reviewId: Id,
    citationFileChecksum: string,
  ) {
    return this._reviewsRepository.isCitationFileAlreadyImported(
      reviewId,
      citationFileChecksum,
    )
  }

  async addAttributeStructure(
    reviewId: Id,
    data: { label: string; question: string; order: number },
  ) {
    return this._reviewsRepository.addAttributeStructure(reviewId, data)
  }

  async addMultipleAttributesStructure(
    reviewId: Id,
    data: { label: string; question: string; order: number }[],
  ) {
    return this._reviewsRepository.addMultipleAttributesStructure(
      reviewId,
      data,
    )
  }

  async updateStudyDataExtractionAttribute(
    reviewId: Id,
    studyId: Id,
    data: { id: string; value: string },
  ) {
    return this._reviewsRepository.updateStudyDataExtractionAttribute(
      reviewId,
      studyId,
      data,
    )
  }

  async lockPlan(reviewId: Id) {
    return this._reviewsRepository.lockPlan(reviewId)
  }

  async unlockPlan(reviewId: Id) {
    return this._reviewsRepository.unlockPlan(reviewId)
  }

  async deleteAttributeStructure(reviewId: Id, attributeStructureId: string) {
    return this._reviewsRepository.deleteAttributeStructure(
      reviewId,
      attributeStructureId,
    )
  }

  async editAttributeStructure(
    reviewId: Id,
    updatedAttribute: AttributeStructure,
  ) {
    return this._reviewsRepository.editAttributeStructure(
      reviewId,
      updatedAttribute,
    )
  }

  async addAuthor(reviewId: Id, authorId: string) {
    await this._reviewsRepository.addAuthor(reviewId, authorId)
  }

  async addReviewer(reviewId: Id, reviewerId: string) {
    await this._reviewsRepository.addReviewer(reviewId, reviewerId)
  }

  async addApprover(reviewId: Id, approverId: string) {
    await this._reviewsRepository.addApprover(reviewId, approverId)
  }
  async addExternalApprover(reviewId: Id, externalApproverId: string) {
    await this._reviewsRepository.addExternalApprover(
      reviewId,
      externalApproverId,
    )
  }

  async removeAuthor(reviewId: Id, authorId: string) {
    await this._reviewsRepository.removeAuthor(reviewId, authorId)
  }

  async removeReviewer(reviewId: Id, reviewerId: string) {
    await this._reviewsRepository.removeReviewer(reviewId, reviewerId)
  }

  async removeApprover(reviewId: Id, approverId: string) {
    await this._reviewsRepository.removeApprover(reviewId, approverId)
  }

  async removeExternalApprover(reviewId: Id, externalApproverId: string) {
    await this._reviewsRepository.removeExternalApprover(
      reviewId,
      externalApproverId,
    )
  }

  async addCustomImportSourceToPlan(
    reviewId: Id,
    importSource: {
      name: string
      url: string
      type: ImportSourceType
      description?: string
    },
  ) {
    return this._reviewsRepository.addCustomImportSourceToPlan(
      reviewId,
      importSource,
    )
  }

  async importRisSearch({
    citationFiles,
    date,
    query,
    filters,
    reviewId,
    importSourceId,
  }: {
    query?: string
    filters?: string
    citationFiles: File[]
    date: string
    reviewId: number
    importSourceId: string
  }) {
    return this._reviewsRepository.importRisSearch({
      query,
      filters,
      citationFiles,
      date,
      reviewId,
      importSourceId,
    })
  }

  async importCitationSearch({
    citationFiles,
    reviewId,
    parentStudyId,
  }: {
    citationFiles: File[]
    reviewId: number
    parentStudyId: number
  }) {
    return this._reviewsRepository.importCitationSearch({
      citationFiles,
      reviewId,
      parentStudyId,
    })
  }

  async answerStudyQuestion(
    studyId: number,
    question: string,
  ): Promise<string> {
    return this._reviewsRepository.answerStudyQuestion(studyId, question)
  }

  async findRunningImports(reviewId: number): Promise<ImportSearchJob[]> {
    return this._reviewsRepository.findRunningImports(reviewId)
  }

  async findRunningStudiesPdfImports(reviewId: number): Promise<
    {
      jobId: string
      status: PdfStudyRetrievalStatus
      studyId: number
    }[]
  > {
    return this._reviewsRepository.findRunningStudiesPdfImports(reviewId)
  }

  async addAppraisalCriteria(
    reviewId: number,
    data: { appraisalCriterionId: string; answer: string },
  ): Promise<void> {
    return this._reviewsRepository.addAppraisalCriteria(reviewId, data)
  }

  async addInclusionCriterion(
    reviewId: Id,
    newCriterion: { criterionType: InclusionCriterion; criterion: string },
  ) {
    return this._reviewsRepository.addInclusionCriterion(reviewId, newCriterion)
  }

  async deleteInclusionCriterion(
    reviewId: Id,
    data: { criterionType: InclusionCriterion; criterion: string },
  ) {
    return this._reviewsRepository.deleteInclusionCriterion(reviewId, data)
  }

  async deleteAppraisalCriterionAnswer(
    reviewId: Id,
    data: { answerId: string; appraisalCriterionId: string },
  ) {
    return this._reviewsRepository.deleteAppraisalCriterionAnswer(
      reviewId,
      data,
    )
  }

  async lock(reviewId: Id): Promise<void> {
    return this._reviewsRepository.lockReview(reviewId)
  }

  async unlock(reviewId: Id): Promise<void> {
    return this._reviewsRepository.unlockReview(reviewId)
  }

  async archive(reviewId: Id): Promise<void> {
    return this._reviewsRepository.archiveReview(reviewId)
  }

  async unarchive(reviewId: Id): Promise<void> {
    return this._reviewsRepository.unarchiveReview(reviewId)
  }
}
