<template>
  <teleport to="#header-middle">
    <ReviewTitle />
  </teleport>

  <teleport to="#toolbar">
    <div>
      <Tabs
        v-model:step="activeStep"
        v-model:sub-step="activeSubStep"
        :steps="tabs"
      >
        <template #[getStepSlotName(Step.plan)]>
          <div class="flex items-center px-8 py-3 gap-2">
            <div class="leading-none">
              <LockOpenIcon
                v-if="
                  review.entity.value.plan?.lockState ===
                  ReviewLockState.UNLOCKED
                "
                class="text-green-600 w-5 h-auto stroke-2"
              />
              <LockClosedIcon
                v-else-if="
                  review.entity.value.plan?.lockState === ReviewLockState.LOCKED
                "
                class="text-red-600 w-5 h-auto stroke-2"
              />
            </div>
            <div class="leading-none">Plan</div>
          </div>
        </template>
        <template #[getStepSlotName(Step.execute)]="{ disabled }">
          <div
            v-tooltip="
              review.entity.value?.plan?.lockState === ReviewLockState.UNLOCKED
                ? 'You need to lock the plan to proceed'
                : ''
            "
            class="flex items-center px-8 py-3 gap-2"
          >
            <div class="leading-none">
              <LockOpenIcon
                v-if="!review.entity.value.isLocked"
                class="text-green-600 w-5 h-auto stroke-2"
                :class="{ 'text-green-600/50': disabled }"
              />
              <LockClosedIcon
                v-else-if="review.entity.value.isLocked"
                class="text-red-600 w-5 h-auto stroke-2"
                :class="{ 'text-red-600/50': disabled }"
              />
            </div>
            <div class="leading-none">Execute</div>
          </div>
        </template>
        <template #[lockSubStepSlotName]>
          <div
            v-tooltip="
              review.entity.value.plan?.lockState === ReviewLockState.LOCKED
                ? 'Unlock plan'
                : 'Lock plan'
            "
            class="px-4 py-2"
          >
            <OutlineLockOpenIcon
              v-if="
                review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED
              "
              class="text-green-600 w-6 h-auto"
            />
            <OutlineLockClosedIcon
              v-else-if="
                review.entity.value.plan?.lockState === ReviewLockState.LOCKED
              "
              class="text-red-600 w-6 h-auto"
            />
          </div>
        </template>
        <template #[lockReviewSlotName]>
          <div
            v-tooltip="
              review.entity.value.isLocked ? 'Unlock review' : 'Lock review'
            "
            class="px-4 py-2"
          >
            <OutlineLockOpenIcon
              v-if="!review.entity.value?.isLocked"
              class="text-green-600 w-6 h-auto"
            />
            <OutlineLockClosedIcon
              v-else-if="review.entity.value?.isLocked"
              class="text-red-600 w-6 h-auto"
            />
          </div>
        </template>
      </Tabs>
      <div
        v-if="review.isArchived.value"
        class="bg-orange-400/10 text-orange-400 px-4 py-2 flex items-center gap-2"
      >
        <InformationCircleIcon class="w-5 h-5" />
        The review is archived, you can't make changes.
      </div>
      <div
        v-else-if="review.isLocked.value"
        class="bg-orange-400/10 text-orange-400 px-4 py-2 flex items-center gap-2"
      >
        <InformationCircleIcon class="w-5 h-5" />
        <span>
          Review is locked, unlock it to make changes.
          <template
            v-if="
              activeStep === Step.execute &&
              (review.displayOptions.isAppraisalStageActive.value ||
                review.displayOptions.isDataExtractionStageActive.value) &&
              review.filteredStudies.value.length <= 0 &&
              review.entity.value?.studies?.length > 0
            "
          >
            No articles have been included.
          </template>
        </span>
      </div>
      <div
        v-else-if="
          [Step.plan].includes(activeStep) &&
          review.entity.value.plan?.lockState === ReviewLockState.LOCKED &&
          !review.isLocked.value
        "
        class="bg-orange-400/10 text-orange-400 px-4 py-2 flex items-center gap-2"
      >
        <InformationCircleIcon class="w-5 h-5" /> The plan is locked, unlock it
        to make changes.
      </div>
      <div
        v-else-if="
          activeStep === Step.execute &&
          (review.displayOptions.isAppraisalStageActive.value ||
            review.displayOptions.isDataExtractionStageActive.value) &&
          review.filteredStudies.value.length <= 0 &&
          review.entity.value?.studies?.length > 0
        "
        class="bg-orange-400/10 text-orange-400 px-4 py-2 flex items-center gap-2"
      >
        <InformationCircleIcon class="w-5 h-5" />
        <span> No articles have been included. </span>
      </div>
    </div>
  </teleport>

  <Plan v-lazy-show="activeStep === Step.plan" :active-step="activeSubStep" />
  <Execute
    :is-shown="activeStep === Step.execute"
    :active-step="activeStep === Step.execute ? activeSubStep : 0"
  />
  <Export
    v-lazy-show="activeStep === Step.export"
    :filtered-studies="review.filteredStudies.value"
  />
</template>

<script lang="ts" setup>
import useReview, { ReviewKey } from '@app/views/Review/use-review'
import { computed, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import ReviewTitle from './Overview/ReviewTitle.vue'
import useLoading from '@app/composables/use-loading'
import Execute from './Execute/Execute.vue'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import Tabs from '@app/components/Global/Tabs.vue'
import Export from './Export/Export.vue'
import Plan from './Plan/Plan.vue'
import {
  LockOpenIcon,
  LockClosedIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/solid'
import {
  LockOpenIcon as OutlineLockOpenIcon,
  LockClosedIcon as OutlineLockClosedIcon,
} from '@heroicons/vue/24/outline'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import useSnackbar from '@app/composables/use-snackbar'
const route = useRoute()
const loading = useLoading()
loading.start()
const review = await useReview(parseInt(route.params.id as string))
provide(ReviewKey, review)
loading.stop()

const snackbar = useSnackbar()

enum Step {
  plan = 1,
  execute = 2,
  export = 3,
  reviewLock = 4,
}

enum PlanSubStep {
  reviewPreset = 1,
  eligibilityCriteria = 2,
  appraisal = 3,
  importSources = 4,
  lock = 5,
}

enum ExecuteSubStep {
  screening = 1,
  appraisal = 2,
  dataExtraction = 3,
}

const tabs = computed(() => {
  const tabs = [
    {
      number: Step.plan,
      title: 'Plan',
      subtitle: '',
      children: [
        {
          number: PlanSubStep.reviewPreset,
          title: 'Scope',
        },
        {
          number: PlanSubStep.eligibilityCriteria,
          title: 'Criteria',
        },
        {
          number: PlanSubStep.appraisal,
          title: 'Appraisal',
        },
        {
          number: PlanSubStep.importSources,
          title: 'Search',
        },
        ...(review.entity.value.plan?.lockState !== ReviewLockState.DISABLED
          ? [
              {
                number: PlanSubStep.lock,
                isDisabled: review.isLocked.value || review.isArchived.value,
                handler: async () => {
                  if (!review.isLocked.value && !review.isArchived.value) {
                    await togglePlanLock()
                  }
                },
              },
            ]
          : []),
      ],
    },
    {
      number: Step.execute,
      title: 'Execute',
      isDisabled:
        review.entity.value.plan?.lockState !== ReviewLockState.DISABLED &&
        !review.isPlanReadonly.value,
      children: [
        {
          number: ExecuteSubStep.screening,
          title: 'Screening',
        },
        {
          number: ExecuteSubStep.appraisal,
          title: 'Appraisal',
        },
        {
          number: ExecuteSubStep.dataExtraction,
          title: 'Synthesis',
        },
        {
          number: Step.reviewLock,
          isDisabled: review.isArchived.value,
          handler: async () => {
            await toggleReviewLock()
          },
        },
      ],
    },
    {
      number: Step.export,
      title: 'Export',
      isDisabled:
        (review.entity.value.plan?.lockState !== ReviewLockState.DISABLED &&
          !review.isPlanReadonly.value) ||
        review.isArchived.value,
    },
  ]

  return tabs
})

const activeStep = ref(
  review.entity.value.plan?.lockState === ReviewLockState.LOCKED
    ? Step.execute
    : Step.plan,
)
const activeSubStep = ref(1)

function getStepSlotName(stepNumber: number): string {
  return `tab-${stepNumber}`
}

const lockSubStepSlotName = `tab-${Step.plan}-substep-${PlanSubStep.lock}`
const lockReviewSlotName = `tab-${Step.execute}-substep-${Step.reviewLock}`

async function lockPlan() {
  loading.start()
  try {
    await review.lockPlan(review.entity.value.id)
    snackbar.show(SnackbarState.SUCCESS, 'Review plan locked successfully')
    if (
      !review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable &&
      !review.entity.value?.plan?.appraisalPlan
        .isOxfordLevelOfEvidenceApplicable &&
      !review.entity.value?.plan?.appraisalPlan.isPeerReviewStatusApplicable
    ) {
      review.displayOptions.isAppraisalStageActive.value = false
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review plan, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function unlockPlan() {
  loading.start()
  try {
    await review.unlockPlan(review.entity.value.id)
    snackbar.show(SnackbarState.SUCCESS, 'Review plan unlocked successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while unlocking review plan, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function togglePlanLock() {
  if (review.entity.value.plan?.lockState === ReviewLockState.LOCKED) {
    await unlockPlan()
  } else {
    await lockPlan()
  }
}

async function lockReview() {
  loading.start()
  try {
    await review.lockReview(review.entity.value?.id)
    snackbar.show(SnackbarState.SUCCESS, 'Review locked successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function unlockReview() {
  loading.start()
  try {
    await review.unlockReview(review.entity.value?.id)
    snackbar.show(SnackbarState.SUCCESS, 'Review unlocked successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while unlocking review, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function toggleReviewLock() {
  if (review.entity.value.isLocked) {
    await unlockReview()
  } else {
    await lockReview()
  }
}
</script>

<style>
.ripple {
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
